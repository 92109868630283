<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CRow>
          <CCol class="d-flex align-items-center">
            <CIcon :content="titleIcon" class="mr-2" />
            Product List
          </CCol>
          <CCol class="d-flex align-items-center justify-content-end">
            <CButton
                color="success"
                square
                size="sm"
                :to="{ name: 'ProductCreate' }"
            >
              出品
            </CButton>
          </CCol>
        </CRow>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :table-filter="{ label: 'フィルター' }"
          :items-per-page-select="{label: '表示件数' }"
          :hover="true" :striped="true" :border="true" :small="true" :fixed="true" :items="productDataTable"
          :fields="tableFields"
          sorter
          pagination
      >
        <template #price="{ item }">
          <td>
            <small v-if="item.original_price">
              <del>{{ item.original_price }}</del>
              <br>
            </small>
            <span class="text-danger font-weight-bold">{{ item.price }}</span>
          </td>
        </template>
        <template #status="{ item }">
          <td>
            <CBadge color="success" v-if="item.status === PRODUCT_STATUS_IN_STOCK">{{
                getStatusLabel(item.status)
              }}
            </CBadge>
            <CBadge color="warning" v-else-if="item.status === PRODUCT_STATUS_OUT_OF_STOCK">{{
                getStatusLabel(item.status)
              }}
            </CBadge>
            <CBadge color="danger" v-else>{{
                getStatusLabel(item.status)
              }}
            </CBadge>
          </td>
        </template>
        <template #action="{ item }">
          <td>
            <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="mx-1 mb-1"
                :to="{name : 'ProductEdit', params: { id: item.uuid }}"
            >Edit
            </CButton>
            <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                class="mx-1"
                @click="deleteProduct(item.uuid)"
            >Delete
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>

  </CCard>
</template>

<script>
import {mapState} from "vuex";
import {
  PRODUCT_STATUS_IN_STOCK,
  PRODUCT_STATUS_OUT_OF_STOCK,
  PRODUCT_STATUS_STOP_BUSINESS,
  productStatusList
} from "../../utils/constants";
import {formatPrice} from "../../utils/helpers";

export default {
  name: "ProductTable",

  computed: {
    ...mapState({
      productList: state => state.product.productList,
    }),

    productDataTable() {
      return this.productList.map(productItem => {
        return {
          uuid: productItem.uuid,
          name: productItem.name,
          price: formatPrice(productItem.price),
          original_price: productItem.original_price ? formatPrice(productItem.original_price) : null,
          status: productItem.status,
          categories_name: productItem.categories.length ? productItem.categories.map(cateItem => cateItem.name).join(', ') : '',
        }
      })
    }
  },

  data() {
    return {
      titleIcon: [
        "512 512",
        "<path fill='var(--ci-primary-color, currentColor)' d='M88,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,160a64,64,0,1,0-64-64A64.072,64.072,0,0,0,424,160Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,424,64Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,448Z' class='ci-primary'/>",
      ],

      productStatusList,
      PRODUCT_STATUS_OUT_OF_STOCK,
      PRODUCT_STATUS_IN_STOCK,
      PRODUCT_STATUS_STOP_BUSINESS,

      loading: true,

      tableFields: [
        {
          key: 'uuid',
          label: 'ID',
        },
        {
          key: 'name',
          label: '名前'
        },
        {
          key: 'price',
          label: "価格"
        },
        {
          key: 'categories_name',
          label: 'カテゴリー'
        },
        {
          key: 'status',
          label: '在庫状況',
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
    };
  },
  methods: {
    getStatusLabel(status) {
      let statusItem = productStatusList.find(statusObj => statusObj.code === status);
      return statusItem ? statusItem.label : '';
    },
    deleteProduct(id) {
      this.$swal
          .fire({
            title: "Delete Product",
            text: "Do you really want to delete selected product?",
            icon: "info",
            showCancelButton: true,
            showConfirmButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show({
                canCancel: false,
              });

              this.$store
                  .dispatch("product/deleteProduct", id)
                  .then((res) => {
                    let { success } = res.data;

                    if (success) {
                      this.$swal.fire({
                        title: "Delete Product",
                        text: "Delete Product Successfully!",
                        icon: "success",
                        willClose: () => {
                          this.$store.dispatch("product/getProductList");
                        },
                      });
                    } else {
                      this.$swal.fire({
                        title: "Delete Product",
                        text: "Delete Product Failed!",
                        icon: "error",
                      });
                    }
                  })
                  .catch(() => {
                    this.$swal.fire({
                      title: "Delete Product",
                      text: "Delete Product Failed!",
                      icon: "error",
                    });
                  })
                  .finally(() => {
                    loader.hide();
                  });
            }
          });
    },
  },
  mounted() {
    this.loading = true;

    this.$store.dispatch('product/getProductList')
        .finally(() => {
          this.loading = false;
        });
  },
};
</script>
