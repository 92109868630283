<template>
  <div>
    <CRow ref="shopCategorySection">
      <CCol col="12" v-if="user">
        <AdminProductTable v-if="user.role_id === USER_ROLE_ADMIN" />
        <ProductTable v-else
            :items="[]"
            :fields="[]"
            hover
            striped
            border
            small
            fixed
            caption="Shop Owner List"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ProductTable from "../../components/products/ProductTable";
import {USER_ROLE_ADMIN} from "../../utils/constants";
import AdminProductTable from "../../components/products/AdminProductTable";

export default {
  name: 'ProductList',

  components: {
    ProductTable,
    AdminProductTable
  },

  computed: {
    ...mapState({
      user: state => state.auth.user ?? {},
    }),
  },

  data() {
    return {
      USER_ROLE_ADMIN,
    }
  },

  metaInfo: {
    title: 'Product Management'
  }
};
</script>
