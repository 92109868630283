<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon :content="titleIcon" class="mr-2" />
        Product List
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :table-filter="{ label: 'フィルター' }"
          :items-per-page-select="{label: '表示件数' }"
          :hover="true" :striped="true" :border="true" :small="true" :fixed="true" :items="productDataTable"
          :fields="tableFields"
          sorter
          pagination
      >
      </CDataTable>
    </CCardBody>

    <CElementCover :opacity="0.8" v-if="loading"/>
  </CCard>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AdminProductTable",

  computed: {
    ...mapState({
      productList: state => state.product.productList,
    }),

    productDataTable() {
      return this.productList.map(productItem => {
        return {
          uuid: productItem.uuid,
          name: productItem.name,
          shop_name: productItem.shop ? productItem.shop.name : '',
          price: productItem.price,
          status: productItem.status,
          categories_name: productItem.categories.length ? productItem.categories.map(cateItem => cateItem.name).join(', ') : '',
        };
      });
    }
  },

  components: {},
  data() {
    return {
      titleIcon: [
        "512 512",
        "<path fill='var(--ci-primary-color, currentColor)' d='M88,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,160a64,64,0,1,0-64-64A64.072,64.072,0,0,0,424,160Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,424,64Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,448Z' class='ci-primary'/>",
      ],

      loading: true,

      tableFields: [
        {
          key: 'uuid',
          label: 'ID',
        },
        {
          key: 'name',
          label: '名前'
        },
        {
          key: 'shop_name',
          label: '店舗'
        },
        {
          key: 'price',
          label: "価格"
        },
        {
          key: 'categories_name',
          label: 'カテゴリー'
        },
        {
          key: 'status',
          label: '在庫状況',
        },
      ],

    };
  },
  methods: {},
  mounted() {
    this.loading = true;

    this.$store.dispatch('product/getProductList', true)
        .finally(() => {
          this.loading = false;
        });
  },
};
</script>
